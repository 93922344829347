import { AuthSchema, AuthValidator } from "@/lib/validators/auth_validator";
import { NextPage } from "next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Space_Grotesk } from "next/font/google";
import Image from "next/image";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Link from "next/link";
import { Icons } from "@/components/icons";
import { useState } from "react";
import { Eye, EyeSlash } from "iconsax-react";

const sg = Space_Grotesk({ subsets: ["latin"] });

export const AUTH_TYPES = ["SignIn", "SignUp"] as const;

interface Props {
  onFormSubmit(creds: AuthSchema): void;
  loading: boolean;
  type: "signin" | "signup";
}

const AuthForm: NextPage<Props> = ({ onFormSubmit, loading, type }) => {
  const form = useForm<AuthSchema>({
    resolver: zodResolver(AuthValidator),
  });

  // 2. Define a submit handler.
  const onSubmit = (values: AuthSchema) => {
    onFormSubmit(values);
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <main>
      <div>
        <div className="w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4">
              <div className="">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="w-full relative">
                      <div className="border rounded-md py-1">
                        <FormControl className="mt-1 peer border-none shadow-none focus-visible:ring-0 placeholder-transparent placeholder-red-500">
                          <Input
                            {...field}
                            type="email"
                            id="email"
                            className="placeholder-transparent peer placeholder-red-500"
                            placeholder=" "
                          />
                        </FormControl>
                        <FormLabel
                          htmlFor="email"
                          className="px-3 mt-1.5 absolute left-0 -top-0 text-gray-600 text-xs transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-600 peer-placeholder-shown:top-2.5 peer-focus:-top-0 peer-focus:text-gray-600 peer-focus:text-xs "
                        >
                          Email
                        </FormLabel>
                      </div>

                      <FormMessage className="text-xs px-1 pb-3" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="w-full relative mt-4">
                      <div className="border rounded-md py-1">
                        <FormControl className="mt-1 peer border-none shadow-none focus-visible:ring-0 placeholder-transparent">
                          <Input
                            {...field}
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="placeholder-transparent"
                            placeholder=" "
                          />
                        </FormControl>
                        <FormLabel
                          htmlFor="password"
                          className="px-3 mt-1.5 absolute left-0 -top-0 text-gray-600 text-xs transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-600 peer-placeholder-shown:top-2.5 peer-focus:-top-0 peer-focus:text-gray-600 peer-focus:text-xs "
                        >
                          Password
                        </FormLabel>
                      </div>
                      <Button
                        className="absolute top-1 right-1"
                        variant="ghost"
                        size="sm"
                        type="button"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {!showPassword ? (
                          <Eye className="h-5 w-5" />
                        ) : (
                          <EyeSlash className="h-5 w-5" />
                        )}
                      </Button>

                      <FormMessage className="text-xs px-1 pb-3" />
                    </FormItem>
                  )}
                />
              </div>

              <div
                className={`flex items-center justify-end mt-2 ${
                  type === "signin" ? "" : "hidden"
                }`}
              >
                <Button variant={"link"} className="p-0 text-xs" type="button">
                  <Link href={"/reset-password"}>Forgot Password?</Link>
                </Button>
              </div>
              <Button
                type="submit"
                size="lg"
                variant="default"
                className="w-full mt-4"
                disabled={loading}
                isLoading={loading}
                // className="w-full mt-2 bg-gradient-to-l from-[#02b5ff] to-[#0c3df4]"
              >
                {type === "signin" ? "Sign In" : "Sign Up"}
              </Button>
            </form>
          </Form>
          {type === "signin" ? (
            <Link href="/sign-up">
              <Button variant="link" className="w-full mt-2">
                Create an account
              </Button>
            </Link>
          ) : (
            <Link href="/sign-in">
              <Button variant="link" className="w-full mt-2">
                Have an account? Sign In
              </Button>
            </Link>
          )}
          <div className="flex justify-between items-center mt-4">
            <p className="text-xs text-zinc-800 dark:text-neutral-300">
              {" "}
              &copy; All rights reserved, 2024
            </p>
            <p className="text-xs text-zinc-800 dark:text-neutral-300">
              Terms & Conditions
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuthForm;
