import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatDate = (date: string): string => {
  const newDate = new Date(date);
  return new Intl.DateTimeFormat("en-US").format(newDate);
};

export const formatDateWTime = (date: string): string => {
  const newDate = new Date(date);
  // const userTimeZone =
  //   Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Colombo";

  // return new Intl.DateTimeFormat("en-US", {
  //   dateStyle: "short",
  //   timeStyle: "short",
  //   timeZone: userTimeZone,
  // }).format(newDate);
  return `${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString()}`;
};

const units: { unit: Intl.RelativeTimeFormatUnit; ms: number }[] = [
  { unit: "year", ms: 31536000000 },
  { unit: "month", ms: 2628000000 },
  { unit: "day", ms: 86400000 },
  { unit: "hour", ms: 3600000 },
  { unit: "minute", ms: 60000 },
  { unit: "second", ms: 1000 },
];
const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

/**
 * Get language-sensitive relative time message from Dates.
 * @param relative  - the relative dateTime, generally is in the past or future
 * @param pivot     - the dateTime of reference, generally is the current time
 */
export function relativeTimeFromDates(
  relative: Date | null,
  pivot: Date = new Date()
): string {
  if (!relative) return "";
  const elapsed = relative.getTime() - pivot.getTime();
  return relativeTimeFromElapsed(elapsed);
}

/**
 * Get language-sensitive relative time message from elapsed time.
 * @param elapsed   - the elapsed time in milliseconds
 */
export function relativeTimeFromElapsed(elapsed: number): string {
  for (const { unit, ms } of units) {
    if (Math.abs(elapsed) >= ms || unit === "second") {
      return rtf.format(Math.round(elapsed / ms), unit);
    }
  }
  return "";
}

export const bankTransferInformation: any = {
  USD: {
    bank_name: "Choice Financial Group (Mercury)",
    account_name: "Amez Cloud L.L.C",
    account_number: "202327473962",
    swift_code: "CHFGUS44021",
    branch: "Kandy",
  },
  LKR: {
    bank_name: "Seylan Bank",
    account_name: "Amez Cloud (Private) Limited",
    account_number: "0170-13528899-001",
    swift_code: "SEYBLKLX",
    branch: "Kandy",
  },
};

export const calcStripeConvenientFee = (amount: number): number => {
  return Number((amount / (1 - 0.029) + 0.31).toFixed(2));
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const currencyMapping = {
  USA: "USD",
  "Sri Lanka": "LKR",
  India: "INR",
  UK: "EUR",
  Qatar: "QAR",
  UAE: "AED",
  Australia: "AUD",
};

export const otherPaymentMethods = {
  PayPal: "PAYPAL",
  Payoneer: "PAYONEER",
  eZCash: "EZCASH",
  mCash: "MCASH",
};

export const currencies = [
  {
    short_code: "US",
    name: "United States",
    currency: "USD",
    disabled: false,
  },
  {
    short_code: "LK",
    name: "Sri Lanka",
    currency: "LKR",
    disabled: false,
  },
  {
    short_code: "IN",
    name: "India",
    currency: "INR",
    disabled: true,
  },
  {
    short_code: "GB",
    name: "United Kingdom",
    currency: "EUR",
    disabled: true,
  },
  {
    short_code: "QA",
    name: "Qatar",
    currency: "QAR",
    disabled: true,
  },
  {
    short_code: "AE",
    name: "United Arab Emirates",
    currency: "AED",
    disabled: true,
  },
  {
    short_code: "AU",
    name: "Australia",
    currency: "AUD",
    disabled: true,
  },
  {
    short_code: "CA",
    name: "Canada",
    currency: "CAD",
    disabled: true,
  },
];

export enum SEND_MONEY_OPTIONS {
  PROFIT_SHARING = "Profit Sharing",
  WITHDRAWALS = "Withdrawals",
  WALLET_RECHARGE = "Wallet Recharge",
}

export enum SEND_MONEY_STATUS {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export enum USER_ROLE_ENUM {
  SUPER_ADMIN = "SUPER_ADMINISTRATOR",
  ADMIN = "ADMINISTRATOR",
  DEVELOPER = "DEVELOPER",
  SALES_ASSOCIATE = "SALES_ASSOCIATE",
  SUPPORT_ASSOCIATE = "SUPPORT_ASSOCIATE",
  FINANCIAL_SPECIALIST = "FINANCIAL_SPECIALIST",
  FINANCIAL_ASSOCIATE = "FINANCIAL_ASSOCIATE",
  ACCOUNTANT = "ACCOUNTANT",
  CUSTOMER = "CUSTOMER",
}

export enum USER_ACCOUNT_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  APPROVED = "APPROVED",
  TEMPORARILY_DISABLED = "TEMPORARILY_DISABLED",
  BLOCKED = "BLOCKED",
}
